.main{
    /* padding: 20px; */
}

.logo{
    color: white;
    font-weight: bold;
}
.role{
    color: white;
    font-size: 14px;
    margin-top:-20px ;
}
.sidebar{
    background-color: #005555;
    border-radius: 5px;
    box-shadow: 0 0 2px gray;
    display: flex;
    /* margin-right: 20px;
    min-height: 100%;
    padding: 10px; */
}
 .content{
    width: 100%;
    height: 100%;
 }
.header{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px gray;
    margin-bottom: 20px;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.site-layout{
    /* padding: 0px 10px; */
    background: white;
}
.body{
    /* background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px gray;
    min-height: 82vh;
    width: 100%;
    padding: 15px;
    overflow-x: scroll !important; */
    padding: 10px;
    min-height: 90vh;
    width: 100%;
    padding: 0px;
    overflow-x: scroll !important;
}
.body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.body-content{
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.campaign-list{
    margin-top: 5em;
    padding: 1em;

}
.menu{
    /* margin-top: 100px; */
    padding: 0 10px;
    display: flex;
}

.menu-item{
    margin-top: 30px;
}

.menu-item a{
    color: rgba(255, 255, 255, 0.727);
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    padding: 0 2px;
}
.menu-item i{
    color: rgba(255, 255, 255, 0.716);
    text-decoration: none;
    font-size: 18px;
    margin:0 15px;
}

.active-menu-item{
    color: white;
    background-color: #013737;
    padding: 5px;
    border-radius: 5px;
}

.register-form{
    padding-top: 70px;
}

.ant-form-item{
    padding: 10px;
}
.campaign-input{
    padding: 0;
}

.confirm-details{
    box-shadow: none;
    border: none;
}

.confirm-details p{
    padding-bottom: 10px !important;
}

.booking-qr-sec{
    max-width: 470px;
    column-count: 2;
}

.entry-content{
    padding: 15px;
}

.iframe-maps iframe {

    width: 100% !important;
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; */
}

.btn-prev{
    background: #F78914;
    color: white
}

.btn-next{
    background: #92CD28;
    color: white
}

.btn-confirm-qr{
    column-count: 1;
    max-width: 400px;
    min-width: 150px;
    margin-bottom: 10px;
}
.ant-input-affix-wrapper{
    max-height: 40px;
    border-radius: 0;
    border: 1px solid rgba(128, 128, 128, 0.521) !important;
}

.ant-card-head-title{
    color: #324e00;
}
.service-title{
    text-align: left;
    padding: 5px;
    color: #324e00;
}
.os-item-desc{
    font-size: 10px;
    color: #000;
    font-weight: 500;
}

.ant-card-body{
    /* min-height: 156px; */
    padding: 0 !important;
}
/* .service-card{
    padding: 5px;
    transition: border-width 0.6s linear;
    
}
.service-card:hover { border-width: 10px; } */
.service-card {
    padding: 5px;
    transition: border-width 0.6s linear;
    cursor: pointer;
  }
  
  .service-card:hover {
    border: solid 1px lightgreen; 
    border-width: 1px; 
    border-style: dashed;
}
  .quantity-input{
    box-shadow: none !important;
  }
  .quantity-input__modifier {
    padding: 0.7rem;
    width: 3rem;
    font-size: 1.5rem;
    background: none !important;
    color: #198754;
    border: 0 solid #dbdbdb;
    font-weight: 900;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
    cursor: pointer;
}

/* .showme {
    display: none;
  }
  
  .showhim:hover .showme {
    display: block;
  } */

  .ant-menu-item-only-child{
    height: auto !important;
  }
  .showhim{
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    padding: 0 2px;
    display: flex;
    flex-direction: column;
  }
  .showme {
    display: none;
  }
  
  .showhim:focus ul{
    display:block;
    background-color: white;
}
.showhim:hover ul{
    display:block;
    background-color: white;
}

.show-info-links{
    display: none !important;
}
.info-links{
    display: contents;
}

.qr-scanner-section{
    margin-top: 90px;
    text-align: center;
    }
    
    .qr-scan-display{
        
    }
    
    .qr-scan-fail{
        padding: 50px;
        font-size: 20px;
        color: red;
        font-weight: 600;
    }
    
    .qr-status-pass{
        margin: 20px;
        color: red;
    }
    .email-note{
        width: 400px;
        color: #605e5e !important;
        font-weight: 500;
        font-size: 12px;
    }
@media screen and (max-width: 768px){

    .btn-custom-reg {
        width: 100% !important;
        margin: 0;
    }
    .show-info-links{
        display: block;
    }
}
.header-action-icon{
    font-size: 30px;
    color: black !important;
    cursor: pointer;
    padding-left: 10px;
}

.ant-scroll-number-only-unit{
     padding-right: -10px !important;
     padding-bottom: -10px !important;
}

.hide-border input {
    border: none !important;
    max-height: 30px;
}

.ttlist-menu{
    background: none;
    width: fit-content;
    color: rgba(255, 255, 255, 0.727);
    font-size: 18px;
    padding: 0 10px;
    text-decoration: none;
    border: none;
    line-height: 28px;
}

.header-action-icon{
   display: none
}
.menu-top{
    background: #92C828;
    float: right;
    min-width:60%;
    height: inherit;
    background:inherit
}

.dates-info{
    padding: 10px
}

.content-body{
   padding-top: 80px !important;
   padding: 0 15px;
}
.ant-steps{
    padding-top: 80px;
    padding-left: 60px;
    padding-right: 60px;
}

.selected-type{
    border: solid 1px green; border-width: 2px;
}

.r-light{
    color: red;
}

.g-light{
    color:green
}

.ant-steps-item-icon{
    background-color: #94bf94 !important;
    border-color: #94bf94 !important;
    color: white !important;
}

.book-info{
    column-count: 2;
    max-width: 400px;
    min-width: 150px;
    background: #f7f769;
}

.book-info p{
  padding: 10px !important;
}
.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover{
    color: white;
    opacity: 1;
    background-color: green !important;
}
.react-player div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 10px;
}

.diff-details{
    font-size: 8px;
    color: dimgray !important;
    font-weight: bolder;
}

.book-form{
    margin-top: 1rem!important;
    padding-left: 5px;
    padding-right: 5px;
}

.note-details{
    font-size: 8px;
    color: darkgreen !important;
    font-weight: bolder;
}
.refund-details{
    font-size: 8px;
    color: darkred !important;
    font-weight: bolder;
}


.ant-card {

    margin-right: 20px;
}

.ant-card .ant-card-cover img{
    width: 150px;
    height: 150px;
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .team-block{
        display: flex;
    }
}

@media screen and (max-width: 768px){
    .ant-card{
        max-width: fit-content;
        margin-bottom: 20px;
        padding: 10px;    
    }
    .menu{
        padding: 0 5px;
    }
    .header-action-icon{
        display: block
     }
     .menu-top{
        background: #92C828;
        float: right;
        width: 20px;
        min-width: 0% !important;
        height: inherit;
        background:inherit
    }
    .ant-menu-item-selected{
        background-color: #5eb956 !important;
      }
}