@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

*{
  font-family: 'Montserrat', sans-serif !important;
}

/* antd override */
input{
  border-radius: 2px !important;
  border: 1px solid rgba(128, 128, 128, 0.521) !important;
  height: 40px !important;
}
input:focus , .ant-picker{
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid gray !important;
}
#search-bar{
  border-radius: autp !important;
  border: none !important;
  height: auto !important;
}
#search-sec{
  padding: 30px;
}
label{
  font-size: 12px !important;
  color: black !important;
}

#participantId{
  border: none !important;
}

.participant-form{
  margin-top: 1em;
  padding: 1em;
}
.raffle-list{
  margin-top: 2em;
  padding: 4em;
}

.ant-select-selection-search-input{
  border: none !important;
}
.store-select{
  border: none !important;
}

.name-role{
  color: white;
  font-size: x-small;
}

#store-input{
  border: none !important;
}

#acForm{
  margin-top: 5em !important;
  padding: 1em !important;
}
#acuForm{
  /* margin-top: 5em !important; */
  padding: 1em !important;
}
.ant-form-item{
  margin: 10px 0 !important;; 
}
.ant-picker-input input{
  border: none !important;
}
.ant-picker{
  border-radius: 2px !important;
  border: 1px solid rgba(128, 128, 128, 0.521) !important;
  height: 40px !important;
  width: 100%;
}
.react-international-phone-country-selector button{
  height: 100%;
}
/* Common styling */

.spinner-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.704);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.spinner-border{
  width: 5rem;
  height: 5rem;
  color: white !important;
}


.lds-hourglass {
  display: inline-block;
  position: absolute;
  top:42%;
  left: 47%;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.page-title{
  font-size: 25px;
  color: rgba(0, 0, 0, 0.833);
  font-weight: bold;
}
.card-title{
  font-size: 22px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.627);

}
.normal-text{
  font-size: 1rem;
}
.card{
  box-shadow: 0 0 2px rgb(189, 188, 188);
  border-radius: 0 !important;
}
.primary-button{
  background-color: green !important;
  border-color: green !important;
  height: 40px !important;
  width: 80% !important;
  color: white !important;
  font-size: 16px !important;
  width: max-content !important;
}
.full-width-button{
  width: 100% !important;
}
p{
  color: rgba(0, 0, 0, 0.673) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.anchor{
  color: black !important;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px !important;
}
.anchor:hover{
  text-decoration: underline !important;
}

.cursor-pointer{
  cursor: pointer;
}


/* authentication pages */

.authentication{
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   /* background-color: #005555; */
}
.authentication-form{
  width: 400px;
  padding: 10px !important;
}

.authentication .card-title{
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #b9fbbe;
  /* color: white !important; */
  max-width: max-content !important;
  padding: 7px 35px;
  margin-left: -40px;
  border-bottom-left-radius: 10px;
}

.btn-custom-login{
  width: 50% !important;
  margin: 100px;  
}
.btn-custom-reg{
  width: 50% !important;
  margin: 200px;
}

.hmenu-item{
  display: contents !important;
  color: #df1e36;
}

.add-space{
  padding: 1em !important;
}

#countryofResidence{
  border: none !important;
}

#city{
  border: none !important;
}

#nationality{
  border: none !important;
}

.book-btn{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* countdown css */
.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #fcc25a;
}

.countdown {
  display: flex;
  width: fit-content;
  height: fit-content;
}

.flip {
  display: inline-block;
  font-size: 72px;
  color: #dd0031;
  margin: 0 10px;
  line-height: 1.2;
  animation: flipAnimation 1s infinite;
}

@keyframes flipAnimation {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.raffle-container {
  text-align: center;
}

.img-cong {
  display: inline-block;
  width: 250px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.raffle-page-name{
  display: flex;
}

.winner-list{
  display: flex;
  justify-content: center;
}

.ant-card-head-title{
  font-size: large;
}

.search-input-reg{
  width: 20rem;
  margin-bottom: 2rem;
}

.search-input-reg .ant-input-search-button{
  min-height: 40px;
}

.config-form{
  margin-top: 5em;
}
.config-form form{
  display: flex;
  margin-left: 2.5em;
}
.config-form form *{
  display: flex;
}

.config-form form button{
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  border: none;
  margin-left: 2em;
}

.form-file-sec{
  display: inline-grid !important;
}